<template>
  <div>
    <nav
      class="flex flex-col h-screen  p-4 fixed top-0 left-0 w-2/3 shadow-xl bg-white z-50 transition-transform transform  ease-in-out duration-300 overflow-scroll"
      :class="[drawerStore.isDrawerOpen ? '-translate-x-0' : '-translate-x-full']"
    >
      <div class="flex flex-col items-center justify-center p-2 mb-4">
        <NuxtImg
          :src="authManager.authUserAvatarUrl.value"
          class="border-2 rounded-full w-20 h-20 border-a-neutral-dim mx-1"
          width="35"
          height="35"
          alt=""
        />
        <span class="font-semibold text-xs">{{ authManager.authUserFullName.value }}</span>

      </div>
      <BaseButton
        @click.native="gotoHome()"
        primary-color-class="a-primary"
        width-class="w-full"
        margin-classes="mb-2"
      >
        <Icon
          name="mdi:home"
          class="px-1 text-3xl"
        />
        Home
      </BaseButton>
      <BaseButton
        @click.native="gotoDashboardMain()"
        primary-color-class="a-primary"
        width-class="w-full"
        margin-classes="mb-2"
      >
        <Icon
          name="mdi:arrow-left"
          class="px-1 text-3xl"
        />
        Back to Dashboard
      </BaseButton>
      <div class="flex flex-col w-full my-5">
        <nuxt-link
          :to="{ name: item.link.routeName, params: item.link.routeParams }"
          class="flex flex-row w-full items-center justify-start text-sm "
          v-for="(item, index) in menuItems"
          :noPrefetch="true"
        >
          <div class="flex flex-row items-center justify-start w-full py-1 px-2">
            <Icon
              :name="item.icon"
              class="mx-1 text-lg w-2/12"
            />
            <span class="w-9/12"> {{ item.title }}</span>
            <span
              v-if="item.badge != null &&
                item.badge.value != null &&
                item.badge.value > 0
                "
              class="flex flex-row text-xs text-white items-center justify-center rounded-full text-center w-1/12"
              :class="[
                item.badge.type == 'error' ? 'bg-a-error' : 'bg-a-secondary',
              ]"
            >{{ item.badge.value }}</span>
            <span
              v-else
              class="px-1"
            >&nbsp;</span>
          </div>
        </nuxt-link>
      </div>
      <slot name="actionButton" />
      <slot name="actionModal" />
      <BaseButton
      primary-color-class="a-neutral-dim"
      margin-classes="mt-10"
        @click="logoutUser()"
      >
        <Icon name="mdi:logout" />
        <span>Logout</span>
      </BaseButton>
    </nav>

    <div
      v-if="drawerStore.isDrawerOpen"
      @click="drawerStore.closeDrawer()"
      class="fixed inset-0 z-40 bg-black opacity-50"
    ></div>
  </div>
</template>

<script setup lang="ts">
const authManager = useAuthManager();
const drawerStore = useDrawer();

const props = defineProps<{
  menuItems: DashboardMenuItem[];
  dashboardPathName: string;
}>()

function gotoHome() {
  navigateTo({
    name: "index",
  });
  drawerStore.closeDrawer();
}

function gotoDashboardMain() {
  navigateTo({ name: props.dashboardPathName, params:{slug:null}, query:{filter:'active'} });
  drawerStore.closeDrawer();
}

async function logoutUser() {
  await authManager.logout('/login')
}

</script>

<style scoped>
a.router-link-exact-active {
  font-weight: 500;
  @apply bg-a-secondary-lightest;
  @apply border-l-4;
  @apply rounded-sm;
  @apply font-semibold;
  @apply border-a-secondary;
  @apply text-a-secondary;
}</style>
