<template>
  <div class="flex flex-col w-full h-full min-h-full bg-white px-2">
    <BaseButton
      @click.native="gotoDashboardMain()"
      primary-color-class="a-primary"
      margin-classes="my-2"
    >
      <Icon
        name="mdi:arrow-left"
        class="px-0.5 text-2xl"
      />
      Dashboard
    </BaseButton>
    <div class="flex flex-col w-full my-5">
      <nuxt-link
        :to="{ name: item.link.routeName, params: item.link.routeParams }"
        class="flex flex-row w-full items-center justify-start text-sm "
        v-for="(item, index) in menuItems"
        :noPrefetch="true"
      >
        <div class="flex flex-row items-center justify-start w-full py-1 px-2">
          <Icon
            :name="item.icon"
            class="mx-1 text-lg w-2/12"
          ></Icon>
          <span class="w-9/12"> {{ item.title }}</span>
          <span
            v-if="item.badge != null &&
              item.badge.value != null &&
              item.badge.value > 0
              "
            class="text-xs text-white rounded-full text-center w-auto"
            :class="[
              item.badge.type == 'error' ? 'bg-a-error' : 'bg-a-secondary',
              item.badge.value>9? 'p-0.5' : 'px-1'
            ]"
          >{{ item.badge.value }}</span>
          <span
            v-else
            class="px-1"
          >&nbsp;</span>
        </div>
      </nuxt-link>
    </div>
    <slot name="actionButton" />
    <slot name="actionModal" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  menuItems: DashboardMenuItem[];
  dashboardPathName: string;
}>()

function gotoDashboardMain() {
  navigateTo({ name: props.dashboardPathName, params:{slug:null}, query:{filter:'active'} })
}

</script>

<style scoped>
a.router-link-exact-active {
  font-weight: 500;
  /* background-color: #e7ebeb; */
  @apply bg-a-secondary-lightest;
  @apply border-l-4;
  @apply rounded-sm;
  @apply font-semibold;
  @apply border-a-secondary;
  @apply text-a-secondary;
}</style>
